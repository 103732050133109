
export const Bio = {
  name: "Fatemeh Faraji",
  roles: [
    "UI/UX Designer",
    "Wordpress Developer"
  ],
  description: "With over 2 years of experience, I'm an experienced UI designer adept in Material Design, focusing on Usability and Accessibility. Proficient in Responsive and Adaptive design, Agile methodology, skilled in Wireframing, User Flows. Known for punctuality, commitment, and attention to detail. Experienced in WordPress e-commerce. I deliver high-quality projects, enhance user experiences, and exceed client expectations.",
  github: "",
  resume: "",
  linkedin: "",
  twitter: "",
  insta: "",
  facebook: ""
};

export const skills = [
  {
    title: "Technical Skills",
    skills: [
      {
        name: "Figma/Adobe XD",
        image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/figma/figma-original.svg"
      },
      {
        name: "Wireframing/Sketching",
        image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/sketch/sketch-original.svg"
      },
      {
        name: "Usability/Accessibility",
        image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/accessibility/accessibility-original.svg"
      },
      {
        name: "Material Design",
        image: "https://material.io/design/static/images/icons/default.svg"
      },
      {
        name: "Agile Methodology",
        image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/agile/agile-original.svg"
      },
      {
        name: "Team Working",
        image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/collaboration/collaboration-original.svg"
      },
      {
        name: "Creative/Innovative",
        image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/innovation/innovation-original.svg"
      },
      {
        name: "SEO (Google/Bing/Yandex)",
        image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/seo/seo-original.svg"
      },
      {
        name: "Optimizing WordPress",
        image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/wordpress/wordpress-original.svg"
      },
      {
        name: "Elementor Page Builder",
        image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/elementor/elementor-original.svg"
      },
      {
        name: "Deploy Project on Shared Host",
        image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/deployment/deployment-original.svg"
      },
      {
        name: "Multilanguage Website",
        image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/multilanguage/multilanguage-original.svg"
      }
    ]
  }
];

export const experiences = [
  {
    id: 0,
    img: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/company/company-original.svg",
    role: "WordPress Developer / SEO / Optimize",
    company: "4KGroup",
    location: "Istanbul, Turkey",
    date: "2022 - 2023",
    desc: "4KGroup Holding was established in 2003 as one of the leading companies in the field of importing and exporting, maintenance of heavy machinery, as well as in real estate buying and selling in the United Arab Emirates. Optimized 4KGroup's website speed, registered the site on search engines like Bing and Yandex, implemented rich snippet techniques for SEO, proficient in working with AI chatbots, increased website loading speed, and conducted effective SEO targeting rich keywords.",
    achievements: [
      "Implemented effective content marketing strategies.",
      "Conducted comprehensive website audits and optimizations.",
      "Collaborated with cross-functional teams to improve website performance and user experience.",
      "Stayed updated with industry trends and implemented best practices."
    ],
    links: [
      {
        title: "4KGroup",
        url: "https://4kgroup.com"
      },
      {
        title: "4KAdblue",
        url: "https://4kadblue.com"
      }
    ]
  },
  {
    id: 1,
    img: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/lingberry/lingberry-original.svg",
    role: "UI/UX Designer",
    company: "LingBerry",
    location: "Canada",
    date: "2020",
    desc: "LingBerry is an English language learning application encompassing six sections: Listening, Reading, Vocabulary, Grammar, Speaking, and Writing. I collaborated with a Canadian startup, successfully implemented a solo-designed application, developed user-friendly interfaces, implemented Material Design principles effectively, incorporated Usability and Accessibility features, and met project deadlines with punctuality and precision.",
    achievements: [],
    links: [
      {
        title: "Figma UI Link",
        url: "https://figma.com"
      }
    ]
  }
];

export const education = [
  {
    id: 0,
    img: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/education/education-original.svg",
    school: "Kalinga Institute of Industrial Technology, Bhubaneswar",
    location: "India",
    date: "2021 - 2025",
    degree: "Bachelor of Technology - BTech, Computer Science and Engineering",
    desc: "Currently pursuing a Bachelor's degree in Computer Science and Engineering at Kalinga Institute of Industrial Technology, Bhubaneswar. Completed 4 semesters with a CGPA of 8.71. Courses in Data Structures, Algorithms, Object-Oriented Programming, Database Management Systems, Operating Systems, Computer Networks, and a member of the Google Developers Student Club (GDSC) at KIIT."
  },
  {
    id: 1,
    img: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/education/education-original.svg",
    school: "Islamic Azad University, Ardabil",
    location: "Iran",
    date: "2018 - 2020",
    degree: "Software Engineering",
    desc: ""
  }
];


export const projects = [
 
 
    {
      id: 0,
      title: "4KGroup Website Optimization",
      date: "2022 - 2023",
      description:
        "Optimized the 4KGroup website's speed, improved SEO targeting, and enhanced the overall user experience. Implemented rich snippet techniques for SEO and ensured the site was registered on Bing and Yandex search engines. The project also included the development of multilingual support and the deployment of AI chatbots.",
      image: "/projects/4kgroup.jpg",
      tags: [
        "WordPress",
        "SEO",
        "Multilanguage",
        "AI Chatbots",
        "Rich Snippets"
      ],
      github: "",
      webapp: "https://4kgroup.com"
    },
    {
      id: 1,
      title: "4KAdblue Website Development",
      date: "2022",
      description:
        "Designed and developed a multilingual website for 4KAdblue from scratch, focusing on clean UI/UX design, SEO optimization, and responsive design. The project involved close collaboration with the client to meet their specific needs and ensuring high performance and accessibility standards.",
        image: "/projects/4kadblue.jpg",
      tags: [
        "WordPress",
        "SEO",
        "Responsive Design",
        "Multilanguage"
      ],
      github: "",
      webapp: "https://4kadblue.com"
    },
    {
      id: 2,
      title: "LingBerry UI/UX Design",
      date: "2020",
      description:
        "Developed the UI/UX design for LingBerry, an English language learning application. The design incorporated Material Design principles and focused on enhancing usability and accessibility. The project was completed as a solo effort, with attention to detail and adherence to deadlines.",
      image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/design/design-original.svg",
      tags: [
        "Figma",
        "Material Design",
        "Usability",
        "Accessibility"
      ],
      github: "",
      webapp: ""
    },
    {
      id: 3,
      title: "Trackify",
      date: "Jun 2023 - Jul 2023",
      description:
        "Trackify is a web application designed to streamline task management and enhance productivity in the workplace. It provides a user-friendly interface for employers to keep track of their employees' daily work activities and empowers employees to log their tasks efficiently.",
      image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/task/task-original.svg",
      tags: [
        "Figma"
      ],
      github: "https://github.com/rishavchanda/Trackify",
      webapp: "https://trackify.duckdns.org"
    }
 
  
];

export const TimeLineData = [
  { year: 2017, text: "Started my journey" },
  { year: 2018, text: "Worked as a freelance developer" },
  { year: 2019, text: "Founded JavaScript Mastery" },
  { year: 2020, text: "Shared my projects with the world" },
  { year: 2021, text: "Started my own platform" }
];